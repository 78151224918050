(function (document, history, location) {
  const HISTORY_SUPPORT = !!(history && history.pushState);

  const anchorScrolls = {
    ANCHOR_REGEX: /^#[^ ]+$/,
    OFFSET_HEIGHT_PX: 200,

    init: function () {
      this.scrollToCurrent();
      window.addEventListener('hashchange', this.scrollToCurrent.bind(this));
      document.body.addEventListener('click', this.delegateAnchors.bind(this));
    },

    getFixedOffset: function () {
      return this.OFFSET_HEIGHT_PX;
    },

    scrollIfAnchor: function (href, pushToHistory) {
      if (!this.ANCHOR_REGEX.test(href)) {
        return false;
      }

      const match = document.getElementById(href.slice(1));
      if (match) {
        const rect = match.getBoundingClientRect();
        const anchorOffset =
          window.pageYOffset + rect.top - this.getFixedOffset();
        window.scrollTo({ top: anchorOffset, behavior: 'smooth' });

        if (HISTORY_SUPPORT && pushToHistory) {
          history.pushState({}, document.title, location.pathname + href);
        } else if (HISTORY_SUPPORT) {
          // Remove hash after scrolling
          history.replaceState({}, document.title, location.pathname);
        }
      }

      return !!match;
    },

    scrollToCurrent: function () {
      this.scrollIfAnchor(window.location.hash, false);
    },

    delegateAnchors: function (e) {
      const elem = e.target;

      if (
        elem.nodeName === 'A' &&
        this.scrollIfAnchor(elem.getAttribute('href'), true)
      ) {
        e.preventDefault();
      }
    },
  };

  window.addEventListener(
    'DOMContentLoaded',
    anchorScrolls.init.bind(anchorScrolls)
  );
})(window.document, window.history, window.location);